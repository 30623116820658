import * as React from "react";
import { Link } from "gatsby";
import ExternalLink from "../components/external-link";
import PatientLayout from "../components/patient/layout";
import { Row, Col } from "react-grid-system";
import { Seo } from "../components/seo";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import MedicationGuide from "../components/patient/sticky-side-callouts/medication-guide";



const ResourcesPage = () => {
  const { externalLinkInfo } = useSiteMetadata();
  const { dailymedLinkInfo } = useSiteMetadata();
  return (
    <PatientLayout pageClass="pat-sticky-cout patient-resources">
      <MedicationGuide className="teal"></MedicationGuide>
      <Row>
        <Col sm={12} md={10}>
          <section>
            <h1 className="first">Resources for patients and caregivers</h1>
            <h3 className="h3-teal">Helpful programs and information</h3>
            <p>If your healthcare provider has prescribed Hysingla ER as part of your treatment plan, you will find valuable information in these resources:</p>
          </section>
          <section className="btm-spacer">
          <ul className="bullet-list">
									<li>
                    <span>The <span className='text-bold'>Hysingla Medication Guide</span>, which gives you important safety information about Hysingla ER</span>
									</li>
							      <ExternalLink
                      externalURL="https://dailymed.nlm.nih.gov/dailymed/fda/fdaDrugXsl.cfm?setid=b7d23ac2-e776-9f62-3290-c64c2d6eb353&type=display#section-17"
                      messageTitle={dailymedLinkInfo.TITLE}
                      messageBody={externalLinkInfo.BODY}
                      classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                      classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
                    >
                      View the Medication Guide
                    </ExternalLink>
                  <br/><br/>
                  <li><span>The <span className='text-bold'>Hysingla Full Prescribing Information</span></span>
									</li>
                  <ExternalLink
                    externalURL="https://dailymed.nlm.nih.gov/dailymed/fda/fdaDrugXsl.cfm?setid=b7d23ac2-e776-9f62-3290-c64c2d6eb353&type=display"
                    messageTitle={dailymedLinkInfo.TITLE}
                    messageBody={externalLinkInfo.BODY}
                    classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                    classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
                  >
                    View the Full Prescribing Information
                  </ExternalLink>
            </ul>
          </section>
          <section>
              <h3>Risk Evaluation and Mitigation Strategy (REMS)</h3>
              <p className="text-bold">Hysingla ER is subject to the REMS for opioid analgesics</p>
              <p>The goal of the REMS is to reduce serious adverse outcomes resulting from inappropriate prescribing, abuse, and misuse of opioid analgesics, while maintaining patient access to pain medications. Adverse outcomes of concern include addiction, unintentional overdose, and death.</p>
              <ul className="hys-list">
                <li><span>FDA has required a REMS for all opioid analgesics</span></li>
                <li><span>
                <ExternalLink
                  externalURL="https://opioidanalgesicrems.com/RpcUI/products.u"
                  messageTitle={externalLinkInfo.TITLE}
                  messageBody={externalLinkInfo.BODY}
                  classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                  classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
                  >Purdue Pharma is a member of the REMS Program Companies</ExternalLink>
                </span></li>
              </ul>
              <p className="text-bold">Hysingla ER should be prescribed only by healthcare professionals who are knowledgeable in the use of potent opioids for the management of chronic pain.</p>
              <p>Documentation and maintenance of careful prescribing and treatment records are essential, and should include:</p>
              <ul className="hys-list">
                <li><span>Physician and patient agreement</span></li>
                <li><span>Proper assessment of the patient</span></li>
                <li><span>Proper prescribing practices</span></li>
                <li><span>Periodic reevaluation of therapy</span></li>
                <li><span>Proper dispensing and correct storage and handling</span></li>
                <li><span>Careful record keeping of prescribing information, including quantity, frequency, and renewal requests, is strongly advised</span></li>
                <li><span>Providing patient and/or caregiver with the{' '}
                <ExternalLink
                  externalURL="https://dailymed.nlm.nih.gov/dailymed/fda/fdaDrugXsl.cfm?setid=b7d23ac2-e776-9f62-3290-c64c2d6eb353&type=display#section-17"
                  messageTitle={dailymedLinkInfo.TITLE}
                  messageBody={externalLinkInfo.BODY}
                  classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                  classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
                  >Medication Guide</ExternalLink></span></li>
                <li><span>Reviewing the Opioid REMS{' '}
                <ExternalLink
                  externalURL="https://opioidanalgesicrems.com/patientCounselingGuide.html"
                  messageTitle={externalLinkInfo.TITLE}
                  messageBody={externalLinkInfo.BODY}
                  classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                  classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
                  >Patient Counseling Guide</ExternalLink> with the patient and/or caregiver at the time of prescribing</span></li>
              </ul>
              <p>For more information, including REMS-compliant educational CME/CE programs on safe prescribing of REMS for opioid analgesics, visit {' '}
                <ExternalLink
                  externalURL="http://www.opioidanalgesicrems.com/"
                  messageTitle={externalLinkInfo.TITLE}
                  messageBody={externalLinkInfo.BODY}
                  classNameLink={externalLinkInfo.LINK_CLASS_NAME}
                  classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
                  >opioidanalgesicrems.com</ExternalLink>.</p>
            </section>
        </Col>
      </Row>
    </PatientLayout>
  );
};

export default ResourcesPage;

export const Head = () => <Seo title="Resources" description="Find helpful resources to assist you in your treatment with Hysingla ER Extended-Release Tablets CII. See Full PI, Medication Guide & Boxed Warning." />;
